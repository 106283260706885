import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel'
import { Grid, Row, Col } from 'react-flexbox-grid';

export class Inputs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preferences: {
                showCSM: false
            }
        }

        this.state.metricsArray = [
            {category: "New Business Metrics", active: true, metrics: [
                {metric: 'numLeads', fieldType: 'number', prompt: '# of Leads/Trials Generated (over the past 12 months)'},
                {metric: 'numOpptys', fieldType: 'number', prompt: '# of Opportunities / Activated Users'},
                {metric: 'numConverted', fieldType: 'number', prompt: '# of Opptys / Users That Became Paying Customers'},
                {metric: 'numReferrals', fieldType: 'number', prompt: '# of Opptys / Users That Came Through Referrals'},
                {metric: 'amtRevenue', fieldType: 'currency', prompt: '$ New Revenue Generated'},
            ]},

            {category: "Renewal Metrics", active: this.state.preferences.showCSM, metrics: [
                {metric: 'numExistingCustomers', fieldType: 'number', prompt: '# Customers You Started With (12 months ago)'},
                {metric: 'amtStartingRevenue', fieldType: 'currency', prompt: '$ Recurring Revenue You Started With (12 months ago)'},
                {metric: 'amtEndingRevenue', fieldType: 'currency', prompt: '$ Revenue Successfully Renewed'},
            ]},

            {category: "Go-To-Market Spend and Yield", active: true, metrics: [
                {metric: 'amtSalesAndMarketing', fieldType: 'currency', prompt: '$ Spent on Sales & Marketing'},                       
                {metric: 'amtLTV', fieldType: 'currency', prompt: '$ Expected Customer Lifetime Value'},
            ]},

            {category: "Growth Target", active: true, metrics: [
                {metric: 'pctTargetGrowth', fieldType: 'percent', prompt: '% You Want to Grow Your ARR in the Next 12 Months'},
            ]}
            
        ];

        this.state.resultsArray = [
          {metric: 'leadToOppty', fieldType: 'percent', prompt: 'Lead to Opportunity %'},
          {metric: 'winPct', fieldType: 'percent', prompt: 'Win %'},
          {metric: 'revPerLead', fieldType: 'currency', prompt: 'Revenue Per Lead'},
          {metric: 'amtACV', fieldType: 'currency', prompt: 'ACV'},
          {metric: 'amtCAC', fieldType: 'currency', prompt: 'CAC'},
          {metric: 'amtCACtoLTV', fieldType: 'number', prompt: 'LTV/CAC'},
          {metric: 'drrPct', fieldType: 'percent', prompt: 'DRR'},
          {metric: 'referralPct', fieldType: 'percent', prompt: 'Referral %'},
          {metric: 'growthPct', fieldType: 'percent', prompt: 'Growth %'}
        ]

        this.state = {
            ...this.state,
            dbConnected: false,
            numLeads: 250,
            numOpptys: 20,
            numConverted: 3,
            amtRevenue: 60000,
            amtSalesAndMarketing: 100000,
            amtLTV: 60000,

            amtStartingRevenue: 50000,
            amtEndingRevenue: 55000,
            numExistingCustomers: 50000/5000,

            numReferrals: 0,
            pctTargetGrowth: 300,
            results: []
        }    
    }

    calculateResults = () => {
        let growthPct = ((this.state.amtEndingRevenue + this.state.amtRevenue) - (this.state.amtStartingRevenue))/(this.state.amtStartingRevenue);
        let amtACV = this.state.amtRevenue / this.state.numConverted;
        let winPct = this.state.numConverted / this.state.numOpptys;
        let leadToOppty = this.state.numOpptys / this.state.numLeads;
        let drrPct = this.state.amtEndingRevenue/this.state.amtStartingRevenue;
        let targetRevenue = 0;
        let targetNewRevenue = 0;
        let targetRenewalRevenue = ((this.state.amtEndingRevenue + this.state.amtRevenue)*drrPct)
        let referralPct = this.state.numReferrals / this.state.numConverted;  
        let amtCACtoLTV = (this.state.amtLTV*1.0) / (this.state.amtSalesAndMarketing*1.0 / this.state.numConverted);
        if(this.state.preferences.showCSM)
        {
            targetRevenue = (((this.state.amtEndingRevenue + this.state.amtRevenue)*(this.state.pctTargetGrowth/100.0)));
            targetNewRevenue =  Math.abs(targetRevenue - targetRenewalRevenue);
        }
        else
        {
            // console.log(this.state.pctTargetGrowth/100.0)
            targetNewRevenue = targetRevenue = (this.state.amtRevenue*(this.state.pctTargetGrowth/100.0));
        }
        let increaseFactor = .05;
        // how much more revenue would we have generated with the same leads
        let increaseRevenueFromIncreasedActivation = (this.state.numLeads * ((leadToOppty+increaseFactor)) * winPct * amtACV) - this.state.amtRevenue;

        // how much more revenue would we have generated with a greater activation + win rate and the same leads
        let increaseRevenueFromIncreasedWins = (this.state.numLeads * ((leadToOppty+increaseFactor)) * (winPct+increaseFactor) * amtACV) - this.state.amtRevenue;

        // how many more leads would we generate with a greater referral %
        let increaseLeadsFromReferrals = (500 * (referralPct+increaseFactor))

        // how much more revenue would we have generated with a great referral %
        let increaseRevenueFromReferrals = ((increaseLeadsFromReferrals) * (winPct+increaseFactor) * amtACV);

        // generate score
        // Highest score of 100
        // CAC to LTV
        // Activation
        // Win
        // Referral

        let score = 0;
        if(amtCACtoLTV >= 3) {
            score = score + 20
        }
        if(amtCACtoLTV > 4) {
            score = score + 5;
        }

        if(leadToOppty >.05) {
            score = score + 10
        }
        
        if(leadToOppty >= .1) {
            score = score + 15;
        }

        if(winPct >= .2) {
            score = score + 25;
        }
        else if(winPct >= .12) {
            score = score + 10;
        }

        if(referralPct > .01) {
            score = score + 10;
        }
        if(referralPct > .08) {
            score = score + 15;
        }
        

        this.setState({
            results: {
                leadToOppty: leadToOppty,
                winPct: winPct,
                revPerLead: (this.state.amtRevenue*1.0) / (this.state.numLeads*1.0),
                amtACV: amtACV,
                amtCAC: (this.state.amtSalesAndMarketing*1.0) / (this.state.numConverted),
                amtCACtoLTV: amtCACtoLTV,
                drrPct: drrPct,
                referralPct: referralPct,
                growthPct: growthPct,
                targetRevenue: targetRevenue,
                targetRenewalRevenue: targetRenewalRevenue,
                targetNewRevenue: targetNewRevenue,
                numLeadsNeededToGrow: targetNewRevenue/amtACV/winPct/leadToOppty,
                increaseFactor: increaseFactor,
                increaseRevenueFromIncreasedActivation: increaseRevenueFromIncreasedActivation,
                increaseRevenueFromIncreasedWins: increaseRevenueFromIncreasedWins,
                increaseLeadsFromReferrals: increaseLeadsFromReferrals,
                increaseRevenueFromReferrals: increaseRevenueFromReferrals,
                score: score
            }
        }, function() {
          // Propagage data up the chain.
          this.props.handleEngineData(this.state);

          if(this.state.dbConnected) {
            this.writeDataToFirestore();
          }
        });
    }

    handleChange = ( values, sourceInfo ) => {
        const { formattedValue, value, floatValue } = values;
        const { event, source } = sourceInfo;
        if(source == 'event' && event && event.target && event.target.name && floatValue != null) {
            this.props.mixpanel.track("Entered GTM Metric", {metric: event.target.name});
            this.setState({ [event.target.name]: floatValue }, function() { 
                this.calculateResults();
            });
        }
    };
    
    componentDidMount() {
      // Runs after the first render() lifecycle
      this.calculateResults();
    }

    componentDidUpdate(prevProps) {
      // Listen for user authentication and then use auth to fetch data
      if(this.props.user && prevProps.user == null) {
        this.bootstrapDataFromFirestore();
      }
    }

    bootstrapDataFromFirestore = () => {
      console.log(this.props.user.uid)
      if(this.props.user) {
        this.props.db.collection("accounts").doc(this.props.user.uid).get().then((doc) => {
            if (doc.exists) {
                // console.log("Document data:", doc.data());
                this.setState(doc.data(), function() {
                  // Start listening for updates
                  this.listenForUpdatesFromFirestore();
                  this.calculateResults();
                })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                // Start listening for updates
                this.listenForUpdatesFromFirestore();
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });        
      }
    }

    listenForUpdatesFromFirestore = () => {
      // console.log("Starting to listen for updates.");
      this.props.db.collection("accounts").doc(this.props.user.uid)
      .onSnapshot((doc) => {
          // console.log("Received update from Firestore, syncing with local state.")
        //   console.log(doc);
          this.setState(doc.data(), function() {
            this.setState({dbConnected: true});
            this.calculateResults();
          });
      });
    }    

    writeDataToFirestore = () => {
      // console.log("Writing data back fo firestore.")
      // Write to firestore
      if(this.props.user) {

        // Write core inputs
        this.props.db.collection("accounts").doc(this.props.user.uid).set({
          numLeads: this.state.numLeads,
          numOpptys: this.state.numOpptys,
          numConverted: this.state.numConverted,
          amtRevenue: this.state.amtRevenue,
          amtSalesAndMarketing: this.state.amtSalesAndMarketing,

          amtLTV: this.state.amtLTV,
          amtStartingRevenue: this.state.amtStartingRevenue,
          amtEndingRevenue: this.state.amtEndingRevenue,
          numExistingCustomers: this.state.numExistingCustomers,

          numReferrals: this.state.numReferrals,
          pctTargetGrowth: this.state.pctTargetGrowth              
        })
          .then(() => {
              console.log("Document successfully written!");
          })
          .catch((error) => {
              console.error("Error writing document: ", error);
          });
        // Write results in separate collection for analysis
        this.props.db.collection("account-results").doc(this.props.user.uid).set({
            ...this.state.results,
            updated_at: + new Date(),
            email: this.props.user.email,
            name: this.props.user.displayName,
            amtRevenue: this.state.amtRevenue
          })
          .then(() => {
              console.log("Document successfully written!");
          })
          .catch((error) => {
              console.error("Error writing document: ", error);
          });
      }
    }

    render() {
      let first = true
      return (
        <Grid fluid>
        <Row className="instructions">
            <Col xs={12}>
                <p>
                    Identify the chokepoints to your SaaS growth in seconds based on <span className='stat'>your key Go-To-Market numbers from the last 12-months</span>. Then follow <span className="stat">actionable steps</span> to accelerate your path to the next stage of growth.
                </p>
            </Col>
        </Row>
        {
            this.state.metricsArray.map(({ category, active, metrics}) => (
                active ? 
                <section key={category}>
                <Row className="inputsHeader">
                  <Col xs={8}><strong>{category}</strong></Col>
                  <Col xs={4} className="subtleInstructions"><strong>{first ? 'enter your metrics 👇' : ''}</strong></Col>
                  {first = false}
                </Row>      
                {
                    metrics.map(({ metric, fieldType, prompt }) => (
                    <Row className="inputRow" key={metric}>
                        <Col xs={6} lg={8}>
                            <p>{prompt}</p>
                        </Col>
                        <Col xs={6} lg={4}>
                        <NumberFormat
                            value={ fieldType == 'percent' ? this.state[metric] : this.state[metric]}                    
                            thousandSeparator={true}
                            key={metric}
                            name={metric}
                            prefix={fieldType == 'currency' ? '$': '' }
                            suffix={fieldType == 'percent' ? '%' : '' }
                            onValueChange={this.handleChange}
                            isNumericString={false}
                        />
                        </Col>
                    </Row>                
                    ))
                }
                </section>                             
                :
                null
            ))            
        }
        </Grid>        
      );
    }
}