import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel'
import { Grid, Row, Col } from 'react-flexbox-grid';

export class Header extends Component {
    render() {
      let manageLogin;
      if(this.props.user) {
        manageLogin = <strong>{'Hi, ' + this.props.user.displayName + ' 👋'} <a onClick={this.props.handleLogout}>(Log out)</a></strong>
        this.props.mixpanel.identify(this.props.user.uid);
        this.props.mixpanel.register({
          user_id: this.props.user.uid,
          email: this.props.user.email
        });
      }
      else {
        manageLogin = ''
      }
      return (
        <Row className="header">
          <Col xs={12} lg={6}>
            <img src="https://www.tkkader.com/wp-content/uploads/TK_Abbreviated-Logo-on-Light-BG-1.png"></img>
            <strong className='logo'>SaaS Unstoppable Go-To-Market Grader</strong>
          </Col>
          <Col xs={12} lg={6} className="userInfo">
            {manageLogin}
          </Col>
        </Row>
      );
    }
}