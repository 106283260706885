import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import './App.scss';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';
import { Header } from './components/Header';
import { Inputs } from './components/Inputs';
import { Outputs } from './components/Outputs';
import { Growbar } from './components/Growbar';
import { Megaphone } from './components/Megaphone';
import Delayed from './components/Delayed';
import ReactPixel from 'react-facebook-pixel';

import {provider, auth, db} from './firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      engine: {},
      user: null,
      isModalOpen: true,
      fullyeEngaged: false,
      focusArea: 'overall',
      showResults: true,
      showSurvey: false
    }

    ReactPixel.init('978251355657625');
    ReactPixel.pageView(); // For tracking page view
  }

  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Email/Password for auth provider.
    signInOptions: [
      // firebase.auth.EmailAuthProvider.PROVIDER_ID
      {
        provider: 'password',
        // To override the full label of the button.
        fullLabel: 'Login with Microsoft',
        buttonColor: '#ccc'
        // iconUrl: '<icon-url-of-sign-in-button>',
        // loginHintKey: 'login_hint',
      }
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    }        
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({user: user, isModalOpen: false})
        // Tracking
        mixpanel.track("User Logged In")
        ReactPixel.init('978251355657625', {em: user.email});
        ReactPixel.track('Lead');
        if(window.location.hash == '#survey') {
          this.setState({showSurvey: true})
        }
        this.setState({showResults: false})
      }
    })
  }  

  onClose = () => {
    return true;
  }

  handleEngineData = (payload) => {
    this.setState({engine: payload})
  }

  handleEngagement = (focusArea) => {
    let fullyEngaged = this.state.focusArea == 'referral' && focusArea == 'overall';
    this.setState({fullyEngaged: fullyEngaged, focusArea: focusArea})
  }

  logout = () => {
    auth.signOut()
    this.setState({user: null})
    window.location.reload(false);
  }

  showResults = () => {
    this.setState({showResults: true});
    mixpanel.track("Calculate Results")
  }

  render() {
    return (
      <Grid fluid>
        <MixpanelConsumer>
          {mixpanel => <Header mixpanel={mixpanel} user={this.state.user} handleLogout={this.logout}/>}
        </MixpanelConsumer> 
        <Row className="main-container">
            <Col xs={12} lg={6} className="inputs">
              <Modal
                open={this.state.isModalOpen}
                center
                onClose={this.onClose}
                showCloseIcon={false}
                classNames={{
                  overlay: 'loginOverlay',
                  modal: 'loginModal',
                }}                
                >
                  <div className="login-container">
                    <Row className="login-details">
                      <Col xs={12} lg={6} className="login-about">
                        <h1>SaaS GTM Grader</h1>                      
                        <p>Grade your SaaS Go-To-Market machine in seconds. Then learn how to <span className="stat">identify the chokepoints</span> and transform your GTM to accelerate your path to the next stage of growth.</p>
                        <p className="privacy">Your data will be stored securely and will be kept private. We promise not to spam you. 
                        By registering you agree to our <a href="https://tkkader.com/terms" target="_new">Terms of Service</a>
                        &nbsp;and our <a href="https://tkkader.com/privacy" target="_new">Privacy Policy</a>. Built with ❤️ by <a href="https://tkkader.com/youtube" target="_new">TK Kader</a></p>
                        <p className="copyright">
                        Copyright © {new Date().getFullYear()} · Ideal Customer Profile, LLC.</p>
                      </Col>
                      <Col xs={12} lg={6} className="login-form">
                      <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
                      </Col>
                    </Row>
                  </div>
              </Modal>
              <MixpanelConsumer>
                {mixpanel => <Inputs mixpanel={mixpanel} handleEngineData={this.handleEngineData} db={db} user={this.state.user}></Inputs>}
              </MixpanelConsumer>
              {this.state.showResults ? 
                ''
              : 
                <button className="btnShowResults button green" onClick={this.showResults}>Calculate My GTM Score &rarr;</button>
              }
            </Col>        
            <Col xs={12} lg={6} className={`outputs ${this.state.showResults}`}>
              <div className="intro__inner">
              <MixpanelConsumer>
                {mixpanel => <Outputs mixpanel={mixpanel} engineData={this.state.engine} handleEngagement={this.handleEngagement}></Outputs>}
              </MixpanelConsumer>                
              </div>
            </Col>
        </Row>
        <Delayed waitBeforeShow={10000}>
          <MixpanelConsumer>
            {mixpanel => <Growbar mixpanel={mixpanel} engineData={this.state.engine} user={this.state.user} fullyEngaged={this.state.fullyEngaged} focusArea={this.state.focusArea} showResults={this.state.showResults}/>}
          </MixpanelConsumer>
        </Delayed>
        <MixpanelConsumer>
          {mixpanel => <Megaphone mixpanel={mixpanel} showSurvey={this.state.showSurvey} db={db} user={this.state.user}/>}
        </MixpanelConsumer>
      </Grid>      
    );
  }
}

export default App;
