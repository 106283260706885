import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import numeral from 'numeral'
import TrainingCard  from './TrainingCard';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

export default class FlywheelDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainingModalVisible: false          
    }
  }

  showTrainingCard = () => {
    this.setState({trainingModalVisible: true});
    this.props.mixpanel.track("View Training", {focusArea: this.props.focusArea})
  }

  closeTrainingCard = () => {
    this.setState({trainingModalVisible: false});      
  }

  generateBenchmarkStatement(benchmarkValue, userValue) {
      if(userValue < benchmarkValue)
      {
          return (
              <span className="benchmark below">You are <span className="stat">below the industry benchmark of {numeral(benchmarkValue).format('0%')}.</span></span>
          )
      }
      else if(userValue == benchmarkValue)
      {
          return (
              <span className="benchmark at">You are <span className="stat">right at the benchmark of {numeral(benchmarkValue).format('0%')}.</span></span>
          )
      }
      else 
      {
          return (
              <span className="benchmark above">You are <span className="stat">above the benchmark of {numeral(benchmarkValue).format('0%')}.</span></span> 
          )
      }
  }

  generateCard() {
      switch(this.props.focusArea)
      {
          case 'overall':
              return (
                  <div className="card">
                      <strong>Overall</strong>                    
                      <hr/>
                      <p>Your GTM Engine Scored <span className="stat">{this.props.engineData.results.score} / 100</span>.</p>
                      <ul>
                        {/* <li>You generated <span className="stat">{numeral(this.props.engineData.amtRevenue).format('$0,0')}</span> in new business with an ACV of <span className="stat">{numeral(this.props.engineData.results.amtACV).format('$0,0')}</span>.</li> */}
                        <li>
                        Your LTV:CAC ratio is <span className="stat">{numeral(this.props.engineData.results.amtCACtoLTV).format('0')}</span>, which means you&nbsp;
                        {
                          this.props.engineData.results.amtCACtoLTV < 3.0 ? (
                            <span>are spending <span className="stat">TOO MUCH</span> to acquire customers.</span>
                          ) : 
                            this.props.engineData.results.amtCACtoLTV < 4.0 ? (
                              <span>have a <span className="stat">relatively <i>cost</i> efficient</span> GTM machine.</span> 
                            ) : (
                              <span>are spending <span className="stat">too little</span> to acquire customers.</span>
                            )
                        }
                        </li>
                        <li>
                        You'd like to grow <span className="stat">{numeral(this.props.engineData.pctTargetGrowth).format('0,00')}%</span> and have a revenue target of <span className="stat">{numeral(this.props.engineData.results.targetNewRevenue).format('$0,0')}</span> in the next 12-months.
                        </li>
                      </ul>
                      <strong className="improvementHeading">How to Improve</strong>
                      <hr/>
                      <ul>
                        <li>You could have realized a revenue upside of <span className="stat">{numeral(this.props.engineData.results.increaseRevenueFromIncreasedWins).format('$0,0')}</span> with the same number of leads with an optimized GTM. </li>
                        <li>In order to grow {numeral(this.props.engineData.pctTargetGrowth).format('0,00')}% you'll have to generate <span className="stat">{numeral(this.props.engineData.results.numLeadsNeededToGrow).format('0,0')}</span> leads with a more scalable GTM machine.<br/><br/></li>
                      </ul>
                  </div>
              )
          case 'acquisition':
              return (
                  <div className="card">
                      <strong>Acquisition</strong>                                            
                      <hr/>
                      <p>You acquired <span className="stat">{numeral(this.props.engineData.numLeads).format('0,0')}</span> leads in the past year.</p>
                      <ul>
                        <li>You have a revenue per lead of <span className="stat">{numeral(this.props.engineData.results.revPerLead).format('$0,0')}</span>.</li>  
                        <li>You're able to turn <span className="stat">{numeral(this.props.engineData.results.leadToOppty).format('0%')}</span> of these leads into real opportunities.</li>
                        {/* <li>You're able to win <span className="stat">{numeral(this.props.engineData.results.winPct).format('0%')}</span> of the opportunties.</li> */}
                        <li>In order to grow {numeral(this.props.engineData.pctTargetGrowth).format('0,00')}% over the next 12 months, you'll have to generate <span className="stat">{numeral(this.props.engineData.results.numLeadsNeededToGrow).format('0,0')}</span> leads.</li>
                      </ul> 
                      <strong className="improvementHeading">How to Improve</strong>
                      <hr/>
                      <p>How do you scale your GTM and generate <span className="stat">{numeral(this.props.engineData.results.numLeadsNeededToGrow).format('0,0')}</span> leads in the next 12-months? You need to differentiate yourself in the market and generate quality leads by revamping <strong>your messaging, positioning and strategic narrative</strong> (your Manifesto).
                      
                      </p>
                  </div>
              )
          case 'activation':
              return (
                  <div className="card">
                      <strong>Activation</strong>                                            
                      <hr/>
                      <p>
                      You're able to activate <span className="stat">{numeral(this.props.engineData.results.leadToOppty).format('0%')}</span> of the leads/trials you generated.
                      {this.generateBenchmarkStatement(.1,this.props.engineData.results.leadToOppty)}
                      </p>                      
                      <strong className="improvementHeading">How to Improve</strong>
                      <hr/>
                      <ul>
                        <li>If you're able to increase your Activation rate by <span className="stat">an additional {numeral(this.props.engineData.results.increaseFactor).format('0%')}</span>, 
                      you'd experience a revenue upside of <span className='stat'>{numeral(this.props.engineData.results.increaseRevenueFromIncreasedActivation).format('$0,0')}</span> 
                      &nbsp;with the same number of leads.</li>
                        <li>The key to improving Activation rates is to acquire better fit leads and nurture them effectively with your Manifesto.</li>
                      </ul>
                      <p>
                      The best way to revamp your quality of pipeline is to do a proper <strong>Ideal Customer Profile</strong> exercise.
                    </p>
                  </div>
              )
          case 'revenue':
              return (
                <div className="card">
                    <strong>Revenue</strong>                                        
                    <hr/>
                    <p>
                        You're able to convert <span className="stat">{numeral(this.props.engineData.results.winPct).format('0%')}</span> of the opportunities you generated.&nbsp;
                        {this.generateBenchmarkStatement(.2,this.props.engineData.results.winPct)}
                        {/* {this.generateBenchmarkStatement(.2,this.props.engineData.results.winPct)} Congratulations on closing <span className="stat">{numeral(this.props.engineData.amtRevenue).format('$0,0')}</span> of new business! */}
                    </p>
                    <strong className="improvementHeading">How to Improve</strong>
                    <hr/>
                    <p>
                        If you increase both your Activation and your Win rate by <span className="stat">an additional {numeral(this.props.engineData.results.increaseFactor).format('0%')}</span>,
                          you'd experience a revenue upside of <span className='stat'>{numeral(this.props.engineData.results.increaseRevenueFromIncreasedWins).format('$0,0')}</span> 
                          &nbsp;with the same number of leads.
                        <br/><br/>
                        Revenue is a function of delivering a consistent flow of quality leads. This is done by running a consistent set of marketing & sales activities that I call a <strong>Broadway Show</strong>.
                    </p>
                </div>
              )
          case 'retention':
              return (
                <div className="card">
                    <strong>Revenue</strong>                                        
                    <hr/>
                    <p>
                        You're able to grow your existing revenue base with a Dollar Retentionn Rate of {numeral(this.props.engineData.results.drrPct).format('0%')}.
                        {this.generateBenchmarkStatement(1.1,this.props.engineData.results.drrPct)} Congratulations on closing {numeral(this.props.engineData.amtEndingRevenue).format('$0,0')} of recurring business!
                    </p>
                </div>
              )
          case 'referral':
            return (
              <div className="card">
                  <strong>Referral</strong>                
                  <hr/>
                  <p>
                      <span className="stat">{numeral(this.props.engineData.results.referralPct).format('0%')}</span> of your new customers were through referrals. There is massive opportunity here to grow faster by mobilizing your existing customers.
                  </p>
                  <strong className="improvementHeading">How to Improve</strong>
                    <hr/>                    
                  <p>                    
                      If you increase your Referral rate by <span className="stat">{numeral(this.props.engineData.results.increaseFactor).format('0%')}</span>
                          &nbsp;by mobilizing 500 of your best customers...&nbsp;
                          you'd generate <span className='stat'>{numeral(this.props.engineData.results.increaseLeadsFromReferrals).format('0,0')}</span> more warm opportunities
                          yielding <span className='stat'>{numeral(this.props.engineData.results.increaseRevenueFromReferrals).format('$0,0')}</span> more in new business given your Win rate.
                    <br/><br/>
                    Pipeline generated with a <strong>Referral Marketing Program</strong> closes at a higher win rate and yields a higher customer lifetime value.
                  </p>
              </div>
            )

      }
  }

  render() {
    if(this.props.engineData && this.props.engineData.resultsArray) {
      return (
        <Grid fluid>
          <Row className="engineChart">
              <Col xs={12} className="engineCard">
                  {this.generateCard()}
                  <button onClick={this.showTrainingCard} className="button watchTraining green">Watch Case Study &rarr;</button>
                  <button className="nextCard alt button" onClick={() => {this.props.nextCardHandler(true)}}>Next &rarr;</button>                    
                  <Modal
                    open={this.state.trainingModalVisible}
                    onClose={this.closeTrainingCard}
                    showCloseIcon={true}
                    classNames={{
                      overlay: 'trainingOverlay',
                      modal: 'trainingModalOuter',
                    }}
                    center
                  >
                    <div className="trainingModal">
                      <TrainingCard engineData={this.props.engineData} focusArea={this.props.focusArea} closeModal={this.closeTrainingCard}/>
                    </div>
                  </Modal>                    
              </Col>
          </Row>
        </Grid>        
      );
    }
    else {
      return null;
    }
  }
}