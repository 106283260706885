import React, { Component } from 'react';
import numeral from 'numeral'
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel'
import { Grid, Row, Col } from 'react-flexbox-grid';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

export default class Scorecard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  goodOrBad = (value, benchmark) => {
    if(value < benchmark) {
      return 'stat bad'
    }
    else if(value == benchmark) {
      return 'stat ok'
    }
    else {
      return 'stat good'
    }
  }

  render() {
    if(this.props.engineData && this.props.engineData.results) {
        return (
        <table className="scorecard">
          <thead>
            <tr>
            <th className='metricName' colSpan="2">GTM Scorecard</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>Revenue per Lead</td>
            <td><span className={this.goodOrBad(this.props.engineData.results.amtCACtoLTV, 3)}>${numeral(this.props.engineData.results.revPerLead).format("0,0")}</span></td>
            </tr>

            <tr>
            <td>Activation %</td>
            <td><span className={this.goodOrBad(this.props.engineData.results.leadToOppty, .1)}>{numeral(this.props.engineData.results.leadToOppty).format("0%")}</span></td>
            </tr>

            <tr>
            <td>Win %</td>
            <td><span className={this.goodOrBad(this.props.engineData.results.winPct, .2)}>{numeral(this.props.engineData.results.winPct).format("0%")}</span></td>
            </tr>

            <tr>
            <td>ACV</td>
            <td><span className={this.goodOrBad(this.props.engineData.results.amtACV, 20000)}>${numeral(this.props.engineData.results.amtACV).format("0,0")}</span></td>
            </tr>

            <tr>
            <td>LTV::CAC</td>
            <td><span className={this.goodOrBad(this.props.engineData.results.amtCACtoLTV, 3)}>{numeral(this.props.engineData.results.amtCACtoLTV).format("0,0")}</span></td>
            </tr>              

            <tr>
            <td>Referral %</td>
            <td><span className={this.goodOrBad(this.props.engineData.results.referralPct, .01)}>{numeral(this.props.engineData.results.referralPct).format("0%")}</span></td>
            </tr>
          </tbody>
        </table>
        )
    }
    else {
        return null
    }
  }
}