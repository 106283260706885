import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Flywheel from './Flywheel';
import FlywheelDetails  from './FlywheelDetails';
import Scorecard from './Scorecard';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';

export class Outputs extends Component {
  constructor(props) {
      super(props);
      this.state = {
        focusArea: 'overall'
      }

      // Warning: Sync with core chart definition below if changed
      if(this.props.engineData.preferences && this.props.engineData.preferences.showCSM)
      {
        this.chartDataReference = ['Referral', 'Retention', 'Revenue', 'Activation', 'Acquisition']
      }
      else
      {
        this.chartDataReference = ['Referral', 'Revenue', 'Activation', 'Acquisition']
      }
  }

  handleFocusChange = (focusArea) => {
    this.setState({focusArea: focusArea})
    this.props.handleEngagement(focusArea);
    this.props.mixpanel.track("Focus Change", {focusArea: focusArea});
  }

  handleNextCard = () => {
    this.props.mixpanel.track("Next Button Clicked", {currentFocusArea: this.state.focusArea});
    if(this.state.focusArea == 'overall'){
      this.handleFocusChange('acquisition')
    }
    else {
      var f = this.state.focusArea;
      f = f.charAt(0).toUpperCase() + f.substr(1).toLowerCase();
      var l = this.chartDataReference.indexOf(f);
      // console.log(l);
      if((l-1)<0) {
        this.handleFocusChange('overall');
      }
      else {
        this.handleFocusChange(this.chartDataReference[l-1].toLowerCase());
      }  
    }
  }

  render() {
    if(this.props.engineData && this.props.engineData.resultsArray) {
      return (
        <Grid fluid>
        <Row className="engineChart">
          <Col xs={12} lg={6}>
            <Flywheel engineData={this.props.engineData} focusArea={this.state.focusArea} handleFocusChange={this.handleFocusChange}></Flywheel>
            <Scorecard engineData={this.props.engineData}></Scorecard>
          </Col>
          <Col xs={12} lg={6}>
            <MixpanelConsumer>
              {mixpanel => <FlywheelDetails mixpanel={mixpanel} engineData={this.props.engineData} focusArea={this.state.focusArea} nextCardHandler={this.handleNextCard}></FlywheelDetails>}
            </MixpanelConsumer>
          </Col>
        </Row>
        </Grid>        
      );
    }
    else {
      return null;
    }
  }
}