import 'survey-react/modern.min.css';
import { StylesManager, Model, Survey } from 'survey-react';
import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel'
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon
} from "react-share";
import ContentEditable from 'react-contenteditable'

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

export class Megaphone extends Component {
    constructor(props) {
      super(props);
      this.state = {
        surveyComplete: false,
        surveyClosed: false,
        testimonialChanged: false
      }
      this.contentEditable = React.createRef();
      StylesManager.applyTheme("modern");
      this.trackEngagement('Started Megaphone Survey')
    }
  
    megaphoneSurveyModel = {
        "pages": 
          [
            {
              "name": "page1",
              "elements": [
                {
                  "type": "rating",
                  "name": "How likely are you to recommend the SaaS GTM Grader to a fellow SaaS Leader?",
                  "isRequired": true,
                  "rateMax": 10
                },
                {
                  "type": "radiogroup",
                  "name": "question1",
                  "visibleIf": "{How likely are you to recommend the SaaS GTM Grader to a fellow SaaS Leader?} >= 8",
                  "title": "What is the biggest value you've gotten from the SaaS GTM Grader?",
                  "isRequired": true,
                  "choices": [
                      {
                      "value": "I learned the key chokepoints in my GTM Machine",
                      "text": "I learned the key chokepoints in my GTM Machine"
                      },
                      {
                      "value": "I figured out exactly what I need to focus on next to scale my SaaS business",
                      "text": "I figured out exactly what I need to focus on next to scale my SaaS business"
                      },
                      {
                      "value": "I walked away with one clear next step to revamp my GTM",
                      "text": "I walked away with one clear next step to revamp my GTM"
                      },
                      {
                      "value": "I learned how to instrument and understand my SaaS GTM Machine",
                      "text": "I learned how to instrument and understand my SaaS GTM Machine"
                      }
                    ],
                  "hasOther": true
                },
                {
                  "type": "comment",
                  "name": "What was disappointing in your experience with the SaaS GTM Grader?",
                  "visible": false,
                  "isRequired": true,
                  "visibleIf": "{How likely are you to recommend the SaaS GTM Grader to a fellow SaaS Leader?} < 8"
                }
              ]
            }
          ],
        "triggers": [
            {
            "type": "complete",
            "expression": "{How likely are you to recommend the SaaS GTM Grader to a fellow SaaS Leader?} < 8"
            }
        ]        
    }

    closeModal = () => {
      this.setState({surveyComplete: false, surveyClosed: true})
    }

    generateeMailto = (testimonial) => {
      let subject = "What's Your SaaS GTM Score?"
      let body = `Hey!

      I just came across this incredible tool for SaaS Leaders and I thought of you.
      
      ` + testimonial + `
      
      The best part about it is it'll help you identify the chokepoints in your GTM and teach you how to fix them.
      
      What's your GTM score? 👇
      https://gtm-grader.com
      
      (I'll show you mine if you show me yours!)
      
      Cheers,
      `

      let m = "mailto:?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
      return m;
    }

    handleTestimonialChange = evt => {
      this.setState({testimonialChanged: true, editableTestimonial: evt.target.value});
    };
  
    trackEngagement = (evt) => {
      this.props.mixpanel.track(evt)
    }  

    onSurveyComplete = (sender) => {
      // Generate Testimonial from survey response
      let rating = sender.data['How likely are you to recommend the SaaS GTM Grader to a fellow SaaS Leader?'];
      let value = sender.data['question1'];
      let feedback = sender.data['What was disappointing in your experience with the SaaS GTM Grader?'];
      if(typeof(feedback) == 'undefined') {
        feedback = null;
      }
      if(typeof(value) == 'undefined') {
        value = null;
      }
      let testimonial = "";
      this.props.mixpanel.track('Completed Megaphone Survey', {rating: rating, value: value, feedback: feedback})
      if(rating >= 8) {
        testimonial = "This SaaS GTM Grader is an incredible tool. " + value + ". I highly recommend it to SaaS Leaders. Check it out at https://gtm-grader.com.";
      }
      this.setState({surveyComplete: true, 
        surveyResults: sender.data, 
        rating: rating, 
        value: value,
        feedback: feedback,
        testimonial: testimonial, 
        editableTestimonial: testimonial,
        mailto: this.generateeMailto(testimonial)}, function() {
          this.writeDataToFirebase();
        })
    }

    writeDataToFirebase = () => {
      this.props.db.collection("megaphone-results").doc(this.props.user.uid).set({
        email: this.props.user.email,
        rating: this.state.rating,
        value: this.state.value,
        feeedback: this.state.feedback,
        testimonial: this.state.testimonial,
        updated_at: new Date()
      })
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch((error) => {
            console.error("Error writing document: ", error);
        });        
    }

    onUpdateTestimonial = () => {
      this.setState({
        testimonial: this.state.editableTestimonial,
        mailto: this.generateeMailto(this.state.editableTestimonial),
        testimonialChanged: false
      }, function() {
        this.writeDataToFirebase();
        this.trackEngagement('Updated Megaphone Testimonial')
      })
    }
  
    render() {
      if(!this.state.surveyComplete) {
        const survey = new Model(this.megaphoneSurveyModel);
        survey.onComplete.add(this.onSurveyComplete);
  
        return(
          <Modal
          open={this.props.showSurvey && !this.state.surveyClosed}
          onClose={this.closeModal}
          showCloseIcon={true}
          classNames={{
            overlay: 'megaphoneOverlay',
            modal: 'megaphoneModal',
          }}
          center
          >
            <div className="megaphoneModalContent">
              <Survey model={survey}></Survey>
              <span className="poweredBy">Powered by Megaphone</span>
            </div>              
          </Modal>
        );  
      }
      else if(this.state.rating >= 8) {
        return (
          <Modal
          open={this.state.surveyComplete}
          onClose={this.closeModal}
          showCloseIcon={true}
          classNames={{
            overlay: 'megaphoneOverlay',
            modal: 'megaphoneModal',
          }}
          center
          >
            <div className="megaphoneModalContent">
              <h2>Refer SaaS GTM Grader to a Friend?</h2>
              <p className="instructions">We're so glad you got value from the GTM Grader. Could you do us a favor and share your testimonial with a fellow SaaS Leader?</p>
              <ContentEditable
                innerRef={this.contentEditable}
                html={this.state.editableTestimonial} // innerHTML of the editable div
                disabled={false}       // use true to disable editing
                onChange={this.handleTestimonialChange} // handle innerHTML change
                tagName='p' // Use a custom HTML tag (uses a div by default)
                className="testimonial"
                autoFocus
              />
              <div className={"updateTestimonialContainer" + (this.state.testimonialChanged ? "" : " hide")}>
                <button className="btnUpdate button green" onClick={this.onUpdateTestimonial}>Update Testimonial</button>
              </div>
              <div className={"shareTestimonialContainer" + (this.state.testimonialChanged ? " hide" : "")}>
                <TwitterShareButton
                  onClick={() => {this.trackEngagement('Tweet with Megaphone')}}
                  url="https://gtm-grader.com"
                  title={this.state.testimonial.replace("https://gtm-grader.com.", "")}
                  className="share-button"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <LinkedinShareButton
                  onClick={() => {this.trackEngagement('LinkedIn with Megaphone')}}
                  url="https://gtm-grader.com"
                  summary={this.state.testimonial.replace("https://gtm-grader.com.", "")}
                  title={this.state.testimonial.replace("https://gtm-grader.com.", "")}
                  className="share-button"
                  source="https://gtm-grader.com">
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>                
                <span className="emailLink">Or, <a onClick={() => this.trackEngagement('Email with Megaphone')} href={this.state.mailto}>email your referral to a friend</a>.</span>
                <br className="clear"/>              
              </div>
              <hr/>
              <span className="poweredBy-static">Powered by Megaphone</span>
            </div>              
          </Modal>
        )
      }
      else {
        return (null)
      }
    }
}

