import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAe7b45OYtJMxUMq9DP1O0rfPsu8PK5fBE",
    authDomain: "gtm-grader.firebaseapp.com",
    projectId: "gtm-grader",
    storageBucket: "gtm-grader.appspot.com",
    messagingSenderId: "395169118850",
    appId: "1:395169118850:web:9829fa3736a357cbba529b",
    measurementId: "G-7ZE4KG540M"
  };

export const app = firebase.initializeApp(firebaseConfig);
export const provider = new firebase.auth.EmailAuthProvider();
export const auth = firebase.auth();
export const db = firebase.firestore();


