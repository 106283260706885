import React, { PureComponent } from 'react';
import { Chart, Doughnut } from 'react-chartjs-2'
import 'chartjs-plugin-labels';
import numeral from 'numeral'

export default class Flywheel extends PureComponent {
    
    constructor(props) {
      super(props);
      this.state = {}

      // Warning: Sync with core chart definition below if changed
      if(this.props.engineData.preferences.showCSM)
      {
        this.chartDataReference = ['Referral', 'Retention', 'Revenue', 'Activation', 'Acquisition']
      }
      else
      {
        this.chartDataReference = ['Referral', 'Revenue', 'Activation', 'Acquisition']
      }
      
    
      Chart.pluginService.register({
        beforeDraw: function(chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            var ctx = chart.chart.ctx;
      
            // Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#000';
            var maxFontSize = centerConfig.maxFontSize || 75;
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
            // Start with a base font of 30px
            ctx.font = "30px " + fontStyle;
      
            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
      
            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = (chart.innerRadius * 2);
      
            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
            var minFontSize = centerConfig.minFontSize;
            var lineHeight = centerConfig.lineHeight || 25;
            var wrapText = false;
      
            if (minFontSize === undefined) {
              minFontSize = 20;
            }
      
            if (minFontSize && fontSizeToUse < minFontSize) {
              fontSizeToUse = minFontSize;
              wrapText = true;
            }
      
            // Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;
      
            if (!wrapText) {
              ctx.fillText(txt, centerX, centerY);
              return;
            }
      
            var words = txt.split(' ');
            var line = '';
            var lines = [];
      
            // Break words up into multiple lines if necessary
            for (var n = 0; n < words.length; n++) {
              var testLine = line + words[n] + ' ';
              var metrics = ctx.measureText(testLine);
              var testWidth = metrics.width;
              if (testWidth > elementWidth && n > 0) {
                lines.push(line);
                line = words[n] + ' ';
              } else {
                line = testLine;
              }
            }
      
            // Move the center up depending on line height and number of lines
            centerY -= (lines.length / 2) * lineHeight;
      
            for (var n = 0; n < lines.length; n++) {
              ctx.fillText(lines[n], centerX, centerY);
              centerY += lineHeight;
            }
            //Draw text in center
            ctx.fillText(line, centerX, centerY);
          }
        }
      });      
    }

    selectedElement = null;
    
    handleClick(evt, element) {
      if(this.selectedElement) {
        // this.selectedElement['_model'].innerRadius = this.selectedElement['_model'].innerRadius - 10;
        // this.selectedElement['_model'].outerRadius = this.selectedElement['_model'].outerRadius - 10;
        // this.selectedElement = null;              
      }
      if (element.length > 0) {
        // this.selectedElement = element[0];
        // element[0]['_model'].innerRadius = element[0]['_model'].innerRadius + 10;
        // element[0]['_model'].outerRadius = element[0]['_model'].outerRadius + 10;
        // element[0]['_chart'].render();
        this.props.handleFocusChange(this.chartDataReference[element[0]['_index']].toLowerCase());
      }
      else {
        this.props.handleFocusChange('overall');
      }
    }

    render() {      
      var chartData = {
        labels: [],
      };
      
      if(this.props.engineData.preferences.showCSM) {
        chartData.labels = [
          'Referral\n' + numeral(this.props.engineData.results.referralPct).format('0%'),
          'Retention\n' + numeral(this.props.engineData.results.drrPct).format('0%') + ' DRR',
          'Revenue\n' + numeral(this.props.engineData.results.winPct).format('0%') + ' Win' ,
          'Activation\n' + numeral(this.props.engineData.results.leadToOppty).format('0%'),
          'Acquistion\n' + numeral(this.props.engineData.numLeads).format('0,0') + ' Leads'
        ]
        chartData.datasets = [{
          data: [.2, .2, .2, .2, .2],
          backgroundColor: [
            '#003f5c',
            '#58508d',
            '#bc5090',
            '#ff6361',
            '#ffa600',
          ],
          hoverOffset: 4
        }]
      }
      else {
        chartData.labels = [
          'Referral\n' + numeral(this.props.engineData.results.referralPct).format('0%'),
          'Revenue\n' + numeral(this.props.engineData.results.winPct).format('0%') + ' Win',
          'Activation\n' + numeral(this.props.engineData.results.leadToOppty).format('0%'),
          'Acquistion\n' + numeral(this.props.engineData.numLeads).format('0,0') + ' Leads'
        ]
        chartData.datasets = [{
          data: [.2, .2, .2, .2],
          backgroundColor: [
            this.props.focusArea == 'referral' || this.props.focusArea == 'overall' ? '#003f5c' : '#ccc',
            this.props.focusArea == 'revenue'  || this.props.focusArea == 'overall' ? '#bc5090' : '#ccc',
            this.props.focusArea == 'activation'  || this.props.focusArea == 'overall' ? '#ff6361' : '#ccc',
            this.props.focusArea == 'acquisition' || this.props.focusArea == 'overall' ? '#ffa600' : '#ccc',
          ],
          hoverOffset: 4
        }]
      }

      var chartOptions = {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
              display: false
          },
          animation: {
            duration: 1000
          },
          onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          },          
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                // return data['labels'][tooltipItem[0]['index']];
                return 'Click to learn how to improve →'                
              },
              label: function(tooltipItem, data) {
                // return data['datasets'][0]['data'][tooltipItem['index']];
                // return 'Click here to learn more about how to improve.'
                return '';
              }
              ,
              afterLabel: function(tooltipItem, data) {
                var dataset = data['datasets'][0];
                var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                // return '(' + percent + '%)';
                // return 'Click here to learn more about how to improve →'
                // return '';
              }
            }
          },          
          onClick: (evt, element) => this.handleClick(evt, element),
          
          layout: {
            padding: 10
          },

          elements: {
            center: {
              text: this.props.engineData.results.score + ' / 100',
              color: '#FF6384', // Default is #000000
              fontStyle: "Helvetica Neue", // Default is Arial
              sidePadding: 20, // Default is 20 (as a percentage)
              minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
              lineHeight: 25 // Default is 25 (in px), used for when text wraps
            }
          },          
          plugins: {
            labels: {
              // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
              render: function (args) {
                return args.label;
              },

              // precision for percentage, default is 0
              precision: 0,
      
              // identifies whether or not labels of value 0 are displayed, default is false
              showZero: true,
      
              // font size, default is defaultFontSize
              fontSize: 12,
      
              // font color, can be color array for each data or function for dynamic color, default is defaultFontColor
              fontColor: '#fff',
      
              // font style, default is defaultFontStyle
              fontStyle: 'normal',
      
              // font family, default is defaultFontFamily
              fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      
              // draw text shadows under labels, default is false
              textShadow: true,
      
              // text shadow intensity, default is 6
              shadowBlur: 10,
      
              // text shadow X offset, default is 3
              shadowOffsetX: -5,
      
              // text shadow Y offset, default is 3
              shadowOffsetY: 5,
      
              // text shadow color, default is 'rgba(0,0,0,0.3)'
              shadowColor: 'rgba(255,0,0,0.75)',
      
              // draw label in arc, default is false
              // bar chart ignores this
              arc: true,
      
              // position to draw label, available value is 'default', 'border' and 'outside'
              // bar chart ignores this
              // default is 'default'
              position: 'default',
      
              // draw label even it's overlap, default is true
              // bar chart ignores this
              overlap: true,
      
              // show the real calculated percentages from the values and don't apply the additional logic to fit the percentages to 100 in total, default is false
              showActualPercentages: false,
      
              // add padding when position is `outside`
              // default is 2
              outsidePadding: 4,
      
              // add margin of text when position is `outside` or `border`
              // default is 2
              textMargin: 4
            }
          }
      };
  
      var showChart = true;

      if(showChart) {
          return (
            <div>
            <Doughnut
              data={chartData}
              options={chartOptions}
              width={100}
              height={100}
            />
            </div>
          )      
      }
      else {
          return null;
      }
    }
}