import React, { Component } from 'react';
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel'
import { Grid, Row, Col } from 'react-flexbox-grid';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


export class Growbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpsellModal: true
    }
  }

  trackEngagement = (evt) => {
    this.props.mixpanel.track("Engaged Growbar", {action: evt})
  }

  closeModal = () => {
    this.setState({showUpsellModal: false})
  }

  render() {
    if(this.props.user && this.props.fullyEngaged) {
      if(this.props.engineData.amtRevenue > 1000000 && this.props.engineData.amtRevenue != 1500000 && false) {
        return (
          <Row className={"growBar " + this.props.showResults}>
            <Col xs={12} lg={7}>
              <span><strong>Revamp your strategic narrative to rise above the competition & align your leadership team with an unstoppable strategy. Accelerate Your Path to the Next Stage of Growth w/TK's SaaS Scale Program.</strong></span>
            </Col>
            <Col xs={12} lg={5}>
                <a onClick={() => this.trackEngagement('Scale')} href="https://tkkader.com/scale?utm_source=grader" className="button green" target="_new">Yes, I Want to Learn More About Working w/TK</a>
            </Col>
            <Modal
              open={this.props.fullyEngaged && this.state.showUpsellModal}
              onClose={this.closeModal}
              showCloseIcon={true}
              classNames={{
                overlay: 'upsellOverlay',
                modal: 'upsellModal',
              }}
              center
            >
              <div className="upsellModalContent">
                <h1>SaaS Strategy Program</h1>                      
                <p><strong>Join the Unstoppable SaaS Strategy Program to drive growth, rise above the competition and exponentially scale.</strong></p>
                <ul>
                  <li>Identify the biggest chokepoints in your business stunting growth</li>
                  <li>Develop a Strategic Plan to scale</li>
                  <li>Build alignment with your leadership team</li>
                  <li>Scale your GTM with a differentiated Strategic Narrative</li>
                  <li>Execute, Scale & Grow your SaaS business</li>
                </ul>
                <a onClick={() => this.trackEngagement('GTM')} href="https://tkkader.com/scale?utm_source=grader" className="button green" target="_new">Yes, I Want to Learn More About Working w/TK</a>                    
              </div>              
            </Modal>          
          </Row>
          )
      }
      else {
        return (
          <Row className={"growBar " + this.props.showResults}>
            <Col xs={12} lg={7}>
              <span><strong>Accelerate Your Path to the Next Stage of Growth w/TK's SaaS Go-To-Market Scale Program.</strong> Through this program, I've helped 100+ B2B SaaS Founders build and execute on a scalable Go-To-Market machine to accelerate growth.</span>
            </Col>
            <Col xs={12} lg={5}>
                <a onClick={() => this.trackEngagement('GTM')} href="https://tkkader.com/gtm?utm_source=grader" className="button green" target="_new">Yes, I Want to Learn More About Working w/TK</a>
            </Col>
            <Modal
              open={this.props.fullyEngaged && this.state.showUpsellModal}
              onClose={this.closeModal}
              showCloseIcon={true}
              classNames={{
                overlay: 'upsellOverlay',
                modal: 'upsellModal',
              }}
              center
            >
              <div className="upsellModalContent">
                <h1>SaaS Go-To-Market Program</h1>                      
                <p><strong>Accelerate growth to $3M ARR and beyond with an Unstoppable Sales Funnel by following a proven step-by-step framework in building a Go-To-Market machine.</strong></p>
                <ul>
                  <li>Clarify your Ideal Customer Profile</li>
                  <li>Develop your Strategic Narrative & Manifesto</li>
                  <li>Run a Broadway Show That Drives Growth</li>
                  <li>AccelerateYour Path to $3M ARR and Beyond</li>
                </ul>
                <a onClick={() => this.trackEngagement('GTM')} href="https://tkkader.com/gtm?utm_source=grader" className="button green" target="_new">Yes, I Want to Learn More About Working w/TK</a>                    
              </div>              
            </Modal>
          </Row>
        )
      }
    } else {
      return (
        <Row className={`growBar  ${this.props.showResults}`}>
          <Col xs={12} lg={7}>
            <span className="shareText">
              <strong>
              Built with ❤️ by <a href="https://tkkader.com/youtube" target="_new">TK Kader & Team Unstoppable</a>
              </strong>
            </span>
          </Col>
          <Col xs={3} lg={1}>
            <span className="emailLink">
              <center>
              <TwitterShareButton
                url={'https://gtm-grader.com'}
                options={{ text: "Check out this free SaaS GTM Grader to identify the chokepoints in your GTM in seconds and learn how to fix them to accelerate growth. Incredible tool!", via: 'tawheed', size: 'large', 'show-count': true }}
              />
              </center>
            </span>
          </Col>
          <Col xs={9} lg={3}>
            <span className="emailLink">Or, <a onClick={() => this.trackEngagement('Email')} href="mailto:?subject=SaaS%20GTM%20Grader&body=Hey%0D%0A%0D%0AI%20just%20came%20across%20this%20incredible%20free%20SaaS%20GTM%20Grader%20tool.%0D%0A%0D%0AJust%20plug%20in%20your%20metrics%20and%20it'll%20score%20the%20efficiency%20of%20your%20GTM%20Machine.%0D%0A%0D%0AThe%20best%20part%20about%20it%20is%20it'll%20help%20you%20identify%20the%20chokepoints%20in%20your%20GTM%20and%20teach%20you%20how%20to%20fix%20them.%0D%0A%0D%0AWhat's%20your%20GTM%20score%3F%20%F0%9F%91%87%0D%0Ahttps%3A%2F%2Fgtm-grader.com%0D%0A%0D%0A(I'll%20show%20you%20mine%20if%20you%20show%20me%20yours!)%0D%0A%0D%0ACheers%2C%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A">email a select group of SaaS Leaders</a>.</span>
          </Col>
        </Row>
      )
    }    
 }
}