import React, { Component } from 'react';
import ReactPlayer from 'react-player'
import { Grid, Row, Col } from 'react-flexbox-grid';
import numeral from 'numeral';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default class TrainingCard extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    } 

    generateTrainingCard() {
        switch(this.props.focusArea) {
            case 'overall': 
                return (
                    <Grid fluid>
                    <Row className="trainingCardHeader">
                        <Col xs={12}>
                            <strong><span className="subtle"></span>How to grow <span className="stat">{numeral(this.props.engineData.pctTargetGrowth).format('0,00')}%</span> and reach your revenue target of <span className="stat">{numeral(this.props.engineData.results.targetNewRevenue).format('$0,0')}</span> in the next 12-months by revamping your GTM machine.</strong>
                        </Col>
                    </Row>
                    <Row className="trainingCardDetails">
                        <Col xs={12} className="trainingCardVideo">
                          <Tabs>
                            <TabList>
                              <Tab>6-Steps to Revamping Your GTM</Tab>
                              <Tab>Case Study: Trellis 5X'd MRR (VC Backed)</Tab>
                            </TabList>

                            <TabPanel>
                              <ReactPlayer
                                  ref={this.ref}
                                  className='player'
                                  width='100%'
                                  controls="true"
                                  url={'https://www.youtube.com/watch?v=isnhS0Sdlzo'}
                              /> 
                              <p>
                              If you've built a product and you've got some revenues flowing but you're not quite at the point where you have predictable and scalable growth, it's time for you to build out a scalable SaaS Go-To-Market Strategy. In this video, I help you map out your SaaS Go-To-Market plan in 6 steps so you can grow faster.
                              </p>                            
                            </TabPanel>

                            <TabPanel>
                              <ReactPlayer
                                  ref={this.ref}
                                  className='player'
                                  width='100%'
                                  controls="true"
                                  url={'https://www.youtube.com/watch?v=F3anw5uK_ho'}
                              /> 
                              <p>
                              In this Case Study, I got on a Zoom call with Fahim Sheikh the CEO of Trellis and dug into all the exciting things happening in their business and most importantly their Go-To-Market strategy since they've applied the key GTM strategies I teach inside of my GTM Program.
                              </p>                            
                            </TabPanel>
                          </Tabs>                        
                        </Col>
                    </Row>
                  </Grid>        
            )
            case 'acquisition': 
                return (
                    <Grid fluid>
                    <Row className="trainingCardHeader">
                        <Col xs={12}>
                            <strong><span className="subtle"></span>How to generate <span className="stat">{numeral(this.props.engineData.results.numLeadsNeededToGrow).format('0,0')}</span> leads that yield <span className="stat">{numeral(this.props.engineData.results.targetNewRevenue).format('$0,0')}</span> in the next 12-months.</strong>
                        </Col>
                    </Row>
                    <Row className="trainingCardDetails">
                      <Col xs={12} className="trainingCardVideo">
                        <Tabs>
                            <TabList>
                              <Tab>Case Study: SieraAI 200% QoQ Growth (VC Backed)</Tab>
                            </TabList>

                            <TabPanel>
                              <ReactPlayer
                                  ref={this.ref}
                                  className='player'
                                  width='100%'
                                  controls="true"
                                  url={'https://tkkader.wistia.com/medias/wyg931nde7'}
                              /> 
                              <p>
                              In this Case Study, Saurav Agrawal of SieraAI talks about how they were able to drive pipeline growth and 200% QoQ growth thanks to the power of a clarified Manifesto and building a scalable GTM Machine.
                              </p>                            
                            </TabPanel>
                          </Tabs>  
                        </Col>
                    </Row>
                  </Grid>        
            )
            case 'activation': 
                return (
                    <Grid fluid>
                    <Row className="trainingCardHeader">
                        <Col xs={12}>
                            <strong><span className="subtle"></span>Increase your Activation rate by <span className="stat">{numeral(this.props.engineData.results.increaseFactor).format('0%')}</span> and generate more business with the same number of leads.</strong>
                        </Col>
                    </Row>
                    <Row className="trainingCardDetails">
                        <Col xs={12} className="trainingCardVideo">
                        <Tabs>
                            <TabList>
                              <Tab>Case Study: Timmy Grew 300% (Bootstrapped)</Tab>
                            </TabList>

                            <TabPanel>
                              <ReactPlayer
                                  ref={this.ref}
                                  className='player'
                                  width='100%'
                                  controls="true"
                                  url={'https://www.youtube.com/watch?v=IBabDBSDru4'}
                              /> 
                              <p>
                              In the past year of being in the Go-To-Market program, Timmy grew over 300% by helping Sports Academies digitize their workflow. How did such a small team achieve this kind of growth? In today’s Unstoppable Sunday episode I dig into the strategy behind this incredible Micro SaaS business, the challenges they faced, and what’s ahead for Timmy.
                              </p>                            
                            </TabPanel>
                          </Tabs>  
                        </Col>
                    </Row>
                  </Grid>        
            )
            case 'revenue': 
                return (
                    <Grid fluid>
                    <Row className="trainingCardHeader">
                        <Col xs={12}>
                            <strong><span className="subtle"></span>Transform How You Sell Your Product and Increase your Win Rate By <span className="stat">{numeral(this.props.engineData.results.increaseFactor).format('0%')}</span>.</strong>
                        </Col>
                    </Row>
                    <Row className="trainingCardDetails">
                        <Col xs={12} className="trainingCardVideo">
                        <Tabs>
                            <TabList>
                              <Tab>Case Study: ProFundCom (Bootstrapped)</Tab>
                            </TabList>

                            <TabPanel>
                              <ReactPlayer
                                  ref={this.ref}
                                  className='player'
                                  width='100%'
                                  controls="true"
                                  url={'https://tkkader.wistia.com/medias/734pto1snz'}
                              /> 
                              <p>
                              In this case study, GTM & Scale Program member, Paul Das, CEO of ProFundCom talks through the success and growth he's seen for his business by revamping his GTM Strategy.
                              </p>                            
                            </TabPanel>                            

                          </Tabs>                        
                        </Col>
                    </Row>
                  </Grid>        
            )
            case 'referral': 
                return (
                    <Grid fluid>
                    <Row className="trainingCardHeader">
                        <Col xs={12}>                        
                            <strong><span className="subtle"></span>How to increase your Referral % by <span className="stat">{numeral(this.props.engineData.results.increaseFactor).format('0%')}</span> and generate more business with little to no marketing spend.</strong>
                        </Col>
                    </Row>
                    <Row className="trainingCardDetails">
                        <Col xs={12} className="trainingCardVideo">
                        <Tabs>
                            <TabList>
                              <Tab>Case Study: Bitesize</Tab>
                            </TabList>

                            <TabPanel>
                              <ReactPlayer
                                  ref={this.ref}
                                  className='player'
                                  width='100%'
                                  controls="true"
                                  url={'https://tkkader.wistia.com/medias/wg3c3cwpx9'}
                              /> 
                              <p>
                                Jess Lee, CEO of Bitesize, talks about how she revamped her GTM strategy and rose above the competition in the hyper-competitive Dealer-Tech space with a Dealer Engagement Platform.
                              </p>                            
                            </TabPanel>
                          </Tabs> 
                        </Col>
                    </Row>
                  </Grid>        
            )
            default:
                    return (
                        <div>&nbsp;</div>
                    )
        }
    }
    
    ref = player => {
    	this.player = player
	}
    
    render() {
      if(this.props.engineData && this.props.engineData.resultsArray) {
        return (
            this.generateTrainingCard()
        )
      }
      else {
        return null;
      }
    }
}